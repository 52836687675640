import * as React from 'react'
import cn from 'classnames'
import { CheckinSkillLevelLabelVm } from './checkin-skill-level-label-vm'

type CheckinSkillLevelLabelProps = {
  level: number | null
  requiredLevel: number | null
  suggested?: boolean
  className?: string
}

export const CheckinSkillLevelLabel: React.VFC<CheckinSkillLevelLabelProps> = (
  props
) => {
  const { level, requiredLevel, suggested = false, className } = props

  const vm = new CheckinSkillLevelLabelVm(level, requiredLevel)

  return (
    <div
      className={cn(
        'border-b h-[26px] border-l border-r border-t border-gray-200 border-solid rounded w-max',
        className
      )}
    >
      <span
        className={cn(
          'align-self-start h-full items-center flex blob py-0 level-text-label whitespace-nowrap',
          vm.blobClassName,
          { 'text-gray-600': suggested }
        )}
      >
        {vm.text}
      </span>
    </div>
  )
}
