import { levelStatus } from '../../utils/checkin-helpers'

export class CheckinSkillLevelLabelVm {
  constructor(
    private level: number | null,
    private requiredLevel: number | null
  ) {}

  get blobClassName() {
    return this.status.replaceAll('_', '-')
  }

  get text() {
    switch (this.status) {
      case 'exceeding':
        return 'Exceeding'
      case 'meeting':
        return 'Meeting'
      case 'not_checked_in':
        return 'Not Checked-In'
      case 'working_towards':
        return 'Working Towards'
    }
  }

  private get status() {
    return levelStatus(this.level, this.requiredLevel)
  }
}
